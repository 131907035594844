<template>
  <div id='payment-state-failed'>
    <div class="icon-wrapper"><span class="icon">x</span></div>

    <h1 class="title">{{ $t(titleKey) }}</h1>
    <div v-if="transactionId" class="transaction-data">
      <span class="label">{{ $t('pages.payment.finalization.states.failed.labels.transaction') }}</span>
      <span class="value">{{ transactionId }}</span>
    </div>
    <span v-if="transactionId" class="description">{{ $t('pages.payment.finalization.states.failed.description') }}</span>
    <span v-if="reason" class="subtitle">{{ $t('pages.payment.finalization.states.failed.subtitle') }}</span>
    <span v-if="reason" class="reason">{{ reason }}</span>
    <base-button
      :isSecondary="true"
      :text="$t('pages.payment.finalization.states.failed.buttons.retry')"
      @clicked="handleBtnClick"
    />
  </div>
</template>

<script>
import BaseButton from '@/components/shared/elements/buttons/ButtonBase';

export default {
  name: 'PaymentStateFailed',
  props: {
    titleKey: {
      type: String,
      required: false,
      default: 'pages.payment.finalization.states.failed.title',
    },
    reason: {
      type: String,
      required: false,
    },
    transactionId: {
      type: [String, Number],
      required: false,
    },
  },
  components: {
    BaseButton,
  },
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {
    handleBtnClick() {
      this.$router.push('/payment/invoice');
    },
  },
};
</script>

<style lang='scss' scoped>
$iconWrapperSize: 135px;
$buttonWidth: 200px;

#payment-state-failed {
  text-align: center;
}

.icon-wrapper {
  width: $iconWrapperSize;
  height: $iconWrapperSize;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $paymentFailedColor;
  color: $white;
  margin: 0 auto 25px;

  @media screen and (max-width: $breakpointDownSm) {
    width: calc(#{$iconWrapperSize} - 15px);
    height: calc(#{$iconWrapperSize} - 15px);
  }

  .icon {
    font-size: 97px;
    font-weight: 700;
    text-transform: uppercase;
    display: inline-block;
    line-height: $iconWrapperSize;

    @media screen and (max-width: $breakpointDownSm) {
      font-size: 80px;
    }
  }
}

.title {
  color: $paymentFailedColor;
  font-weight: 700;
  font-size: 25px;
  margin: 0 0 20px;
  line-height: 1;

  @media screen and (max-width: $breakpointDownXs) {
    font-size: 20px;
  }
}

.transaction-data {
  margin: 0 0 30px;

  @media screen and (max-width: $breakpointDownSm) {
    margin: 0 0 15px;
  }

  .label,
  .value {
    display: block;
    line-height: 1.2;
  }

  .label {
    font-weight: 500;
    font-size: 15px;
    margin: 0 0 2px;

    @media screen and (max-width: $breakpointDownXs) {
      font-size: 12px;
      line-height: 1.4;
    }
  }

  .value {
    font-weight: 700;
    font-size: 20px;
    color: $primaryBlue;

    @media screen and (max-width: $breakpointDownXs) {
      font-size: 16px;
    }
  }
}

.subtitle,
.description {
  display: block;
  font-weight: 500;
  font-size: 15px;
  line-height: 9px;
  margin: 0 0 9px;

  @media screen and (max-width: $breakpointDownSm) {
    line-height: 1.2;
  }
}

.description {
  line-height: 1.2;
  margin: 0 0 15px;
  padding: 0 30px;
}

.reason {
  font-weight: 700;
  text-transform: uppercase;
  color: $paymentFailedColor;
  display: block;
  font-size: 20px;
  margin: 0 0 20px;

  @media screen and (max-width: $breakpointDownXs) {
    font-size: 20px;
  }
}

.button-base {
  min-width: $buttonWidth;
}
</style>
